<template>
  <div>
    <v-select
      v-model="cityId"
      :items="citys"
      label="選擇縣市"
      hide-details
      class="mb-4"
      solo
      item-text="name"
      item-value="id"
      @change="getSchools"
    ></v-select>

    <v-data-table
      hide-default-footer
      disable-sort
      :headers="headers"
      v-if="filterSchools && filterSchools.length > 0"
      :items="filterSchools"
      :items-per-page="filterSchools.length"
      class="elevation-1"
    >
      <template v-slot:[`item.name`]="{ value, item }">
        <div
          cols="auto"
          :class="{ 'primary--text': item.url }"
          @click="toSchool(item)"
        >
          {{ value }}
          <v-icon icon color="primary" small v-if="item.url">
            mdi-open-in-new
          </v-icon>
        </div>
      </template>
      <template v-slot:[`item.setting`]="{ item }">
        <editPathDialog v-model="item.edit" :item="item" @update="getIPC()" />
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-row justify="end">
          <v-col cols="auto">
            <v-btn small disabled text> 強制更新 </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn small disabled text> 重開 </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn small disabled text> 重啟服務 </v-btn>
          </v-col>
        </v-row>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import City from '@/api/admin/City'
import School from '@/api/admin/School'
import IPC from '@/api/develop/IPC'
import editPathDialog from './editPathDialog'
export default {
  name: undefined,
  mixins: [],
  components: {
    editPathDialog
  },
  props: {},
  data: function () {
    return {
      allSchools: [],
      cityId: null,
      citys: [],
      schoolIPC: [],
      headers: [
        {
          text: '學校名稱',
          value: 'name'
        },
        {
          text: '版本',
          value: 'version'
        },
        {
          text: 'OTA 網址',
          value: 'otaUrl'
        },
        {
          text: '設定',
          value: 'setting'
        },
        {
          text: '',
          value: 'action'
        }
      ]
    }
  },
  computed: {
    schools() {
      return this.allSchools.map((school) => {
        const IPCData = this.schoolIPC.find((s) => s.schoolId === school.id)
        school = { ...school, ...IPCData }
        return school
      })
    },
    filterSchools() {
      return this.schools.filter((school) => school.cityId === this.cityId)
    }
  },
  watch: {
    cityId() {
      this.getIPC()
      this.getSchools()
    }
  },
  created: function () {},
  mounted: function () {
    this.getCity()
    this.getSchools()
    this.getIPC()
  },
  destroyed() {},
  methods: {
    toSchool(item) {
      if (!item.url) {
        return
      }

      window.open(item.url, '_blank', 'popup=yes')
    },
    getCity() {
      City.list()
        .then((resp) => {
          this.citys = resp.data
          if (this.citys.length === 1) this.cityId = this.citys[0].id
        })
        .catch((error) => {
          console.log(error.response)
        })
    },
    getSchools() {
      if (!this.cityId) {
        return
      }
      School.list(this.cityId)
        .then((resp) => {
          this.allSchools = resp.data
        })
        .catch((error) => {
          console.log(error.response)
        })
    },
    getIPC() {
      if (!this.cityId) {
        return
      }
      IPC.list(this.cityId)
        .then((resp) => {
          this.schoolIPC = resp.data
        })
        .catch((error) => {
          console.log(error.response)
        })
    }
  }
}
</script>

<style></style>
