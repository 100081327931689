<template>
  <div>
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon small class="mr-2" v-bind="attrs" v-on="on">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5">
          {{ item.name }}
        </v-card-title>

        <v-card-text>
          <v-text-field
            v-model="url"
            label="IPC 網址"
            :rules="[(v) => !!v.length || '此欄位為必填']"
          ></v-text-field>
        </v-card-text>
        <v-card-text>
          <v-text-field
            v-model="otaUrl"
            label="OTA 網址"
            :rules="[(v) => !!v.length || '此欄位為必填']"
          ></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :loading="loading"
            :disabled="!url"
            text
            @click="save()"
          >
            儲存
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import IPC from '@/api/develop/IPC'

export default {
  name: undefined,
  mixins: [],
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object
    }
  },
  data: function () {
    return {
      dialog: false,
      loading: false,
      url: null,
      otaUrl: null
    }
  },
  computed: {},
  watch: {
    value(val) {
      this.dialog = val
    },
    item(val) {
      this.url = val.url
      this.otaUrl = val.otaUrl
    },
    dialog(val) {
      this.$emit('input', val)
    }
  },
  created: function () {},
  mounted: function () {
    this.dialog = this.value
    this.url = this.item.url
    this.otaUrl = this.item.otaUrl
  },
  destroyed() {},
  methods: {
    save() {
      this.loading = true
      const item = this.item
      item.url = this.url
      item.otaUrl = this.otaUrl
      IPC.createOrUpdate({
        url: this.url,
        otaUrl: this.otaUrl,
        schoolId: item.id,
        cityId: item.cityId,
        version: '1.0.1'
      })
        .then((resp) => {
          console.log(resp)
          this.$emit('update', item)
        })
        .catch((error) => {
          console.log(error.response)
        })
        .finally(() => {
          this.dialog = false
          this.loading = false
        })
    }
  }
}
</script>
