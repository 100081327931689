var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-select',{staticClass:"mb-4",attrs:{"items":_vm.citys,"label":"選擇縣市","hide-details":"","solo":"","item-text":"name","item-value":"id"},on:{"change":_vm.getSchools},model:{value:(_vm.cityId),callback:function ($$v) {_vm.cityId=$$v},expression:"cityId"}}),(_vm.filterSchools && _vm.filterSchools.length > 0)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"hide-default-footer":"","disable-sort":"","headers":_vm.headers,"items":_vm.filterSchools,"items-per-page":_vm.filterSchools.length},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('div',{class:{ 'primary--text': item.url },attrs:{"cols":"auto"},on:{"click":function($event){return _vm.toSchool(item)}}},[_vm._v(" "+_vm._s(value)+" "),(item.url)?_c('v-icon',{attrs:{"icon":"","color":"primary","small":""}},[_vm._v(" mdi-open-in-new ")]):_vm._e()],1)]}},{key:"item.setting",fn:function(ref){
var item = ref.item;
return [_c('editPathDialog',{attrs:{"item":item},on:{"update":function($event){return _vm.getIPC()}},model:{value:(item.edit),callback:function ($$v) {_vm.$set(item, "edit", $$v)},expression:"item.edit"}})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"small":"","disabled":"","text":""}},[_vm._v(" 強制更新 ")])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"small":"","disabled":"","text":""}},[_vm._v(" 重開 ")])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"small":"","disabled":"","text":""}},[_vm._v(" 重啟服務 ")])],1)],1)]}}],null,true)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }