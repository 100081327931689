import settings from '@/settings'

/**
 * IPC
 */
class IPC {
  /**
   * IPC 列表
   */
  list(cityId) {
    return axios.get(settings.api.fullPath + `/develop/ipc/list`, {
      params: {
        cityId: cityId
      }
    })
  }

  /**
   * 學校 IPC 位置新增或變更
   */
  createOrUpdate(item) {
    return axios.post(settings.api.fullPath + `/develop/ipc`, [item])
  }
}

var ipc = new IPC()
export default ipc
